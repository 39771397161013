<template>
  <div style="text-align: justify">
    <p class="mb-3">
      In this experiment, a spectroscope is used to look at the emission spectrum of an unknown
      metal. A sodium lamp is used as a calibration light source to determine the d value of the
      diffraction grating of the spectroscope
    </p>

    <p class="mb-3">The following experimental data are given:</p>

    <table class="table table-sm table-hover border" style="margin-left: 50px; margin-bottom: 10px">
      <tbody>
        <tr>
          <td style="vertical-align: middle; height: 25px">
            <stemble-latex content="$\text{Sodium, the calibration source, emission wavelength}$" />
          </td>
          <td style="vertical-align: middle; height: 25px">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="sodiumWL" unit="\text{nm}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle; height: 25px">
            <stemble-latex content="$\text{Angle of light diffraction using the sodium lamp}$" />
          </td>
          <td style="vertical-align: middle; height: 25px">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="sodiumAngle" unit="^{\circ}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle; height: 25px; padding-right: 20px">
            <stemble-latex
              content="$\text{Angle of light diffraction using the unknown metal source}$"
            />
          </td>
          <td style="vertical-align: middle; height: 25px">
            <stemble-latex content="$=\,\,\,$" />
            <number-value :value="metalAngle" unit="^{\circ}" />
          </td>
        </tr>
      </tbody>
    </table>

    <p class="mb-3">
      Refer to Experiment 2 in Chemistry 1110 lab manual for the equation relating the d value of
      the spectroscope to the wavelength and angle of diffraction and also for the Rydberg equation.
    </p>

    <p class="mb-3">1. Using the experimental data, determine:</p>
    <p class="ml-10">
      a) the distance (in nm) between the lines of the diffraction grating using the calibration
      source, the sodium lamp. Show your units.
    </p>
    <p class="ml-10">
      b) the wavelength (in nm) of the radiation from the unknown metal source. Show your units.
    </p>
    <p class="mb-4">
      2. Using the Rydberg equation and the value of the Rydberg constant given in the lab manual,
      calculate the wavelength (in nm) of the radiation emitted when an electron falls from energy
      level
      <number-value :value="energyLevel1" />
      to energy level
      <number-value :value="energyLevel2" unit="\text{.}" /> Hint: Calculate the reciprocal of the
      wavelength directly from the equation and then take the reciprocal of that value to find the
      answer. Show your units.
    </p>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISpectroscopePreLab',
  components: {
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    sodiumWL() {
      return this.taskState.getValueBySymbol('sodiumWL').content;
    },
    sodiumAngle() {
      return this.taskState.getValueBySymbol('sodiumAngle').content;
    },
    metalAngle() {
      return this.taskState.getValueBySymbol('metalAngle').content;
    },
    energyLevel1() {
      return this.taskState.getValueBySymbol('energyLevel1').content;
    },
    energyLevel2() {
      return this.taskState.getValueBySymbol('energyLevel2').content;
    },
  },
};
</script>
